/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import {
  ExchangeOptions,
  OrderTypeOptions,
  RouterSupportedDOCountry,
  SpreadTargetType,
} from "interfaces/enums";

export type ExchangeType = {
  [key in ExchangeOptions]: {
    base_ccy: string;
    quote_ccy: string;
    orderType: OrderTypeOptions;
  };
};

export interface MMType {
  lastOrderOriginClabe?: string;
}
interface SliceState {
  otc: ExchangeType;
  mm: MMType;

  spreadTargetType: SpreadTargetType;
  router_country_dest: RouterSupportedDOCountry;
  direction: "left" | "right";
}

/**
 * Initial state for each exchange
 */
const initialState: SliceState = {
  otc: {
    [ExchangeOptions.Bitso]: {
      base_ccy: "USDT",
      quote_ccy: "MXN",
      orderType: OrderTypeOptions.active_trigger,
    },
    [ExchangeOptions.Binance]: {
      base_ccy: "BTC",
      quote_ccy: "USDT",
      orderType: OrderTypeOptions.active_trigger,
    },
    [ExchangeOptions.CryptoDotCom]: {
      base_ccy: "",
      quote_ccy: "",

      orderType: OrderTypeOptions.active_trigger,
    },
    [ExchangeOptions.Gemini]: {
      base_ccy: "",
      quote_ccy: "",

      orderType: OrderTypeOptions.active_trigger,
    },
    [ExchangeOptions.Huobi]: {
      base_ccy: "",
      quote_ccy: "",

      orderType: OrderTypeOptions.active_trigger,
    },
    [ExchangeOptions.Kraken]: {
      base_ccy: "",
      quote_ccy: "",

      orderType: OrderTypeOptions.active_trigger,
    },
    [ExchangeOptions.OKX]: {
      base_ccy: "",
      quote_ccy: "",

      orderType: OrderTypeOptions.active_trigger,
    },
  },
  mm: {
    lastOrderOriginClabe: "",
  },
  spreadTargetType: SpreadTargetType.Rate,
  direction: "left",
  router_country_dest: RouterSupportedDOCountry.US,
};

export const presetsSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setRouterCountryDest: (
      state,
      action: { payload: RouterSupportedDOCountry },
    ) => {
      state.router_country_dest = action.payload;
    },

    setSpreadTargetType: (state, action: { payload: SpreadTargetType }) => {
      state.spreadTargetType = action.payload;
    },
    setTargetDirection: (state, action: { payload: "left" | "right" }) => {
      state.direction = action.payload;
    },
    setOrderType: (
      state,
      action: {
        payload: { orderType: OrderTypeOptions; exchange: ExchangeOptions };
      },
    ) => {
      state.otc[action.payload.exchange].orderType = action.payload.orderType;
    },

    setBaseCCY: (
      state,
      action: { payload: { base_ccy: string; exchange: ExchangeOptions } },
    ) => {
      state.otc[action.payload.exchange].base_ccy = action.payload.base_ccy;
    },
    setQuoteCCY: (
      state,
      action: { payload: { quote_ccy: string; exchange: ExchangeOptions } },
    ) => {
      state.otc[action.payload.exchange].quote_ccy = action.payload.quote_ccy;
    },
    setLastOrderOriginClabe: (state, action: { payload: string }) => {
      state.mm.lastOrderOriginClabe = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  setBaseCCY,
  setQuoteCCY,
  reset,
  setOrderType,
  setTargetDirection,
  setRouterCountryDest,
  setSpreadTargetType,
  setLastOrderOriginClabe,
} = presetsSlice.actions;

export type PresetsState = ReturnType<typeof presetsSlice.reducer>;
