import { Button } from "@mui/material";

import { IOnboardingDraft } from "interfaces";
import { useTranslation } from "react-i18next";
import { DynamicForm } from "./DynamicForm";
import { useDispatch, useSelector } from "react-redux";
import { addDocument, forwardStep, GlobalState } from "app/slices/globalSlice";

import { FormProvider, useForm } from "react-hook-form";

import { useUpdateOnboardingRegulatedMutation } from "api/institutional/onboarding";
import { useUploader } from "hooks/useUpload";
import { useEffect, useRef } from "react";
import { enqueueSnackbar } from "notistack";
import { errorSnack } from "components/Shared";
import { ParticipantId } from "./ParticipantId";
import { IDTypes } from "interfaces/enums";
import { useMergeFiles } from "./useMergeFiles";
import { UploadOrShow } from "./UploadOrShow";
import { useKeyboardShortcut } from "hooks/useKeyboardShortcut";
import { RevenueForm } from "./Revenue";

export const Regulated = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { step, onboarding } = useSelector<any, GlobalState>(
    (state) => state.global,
  );

  const methods = useForm<IOnboardingDraft>({
    defaultValues: {
      corporate_shareholders: [{ name: "", email: "", phone: "" }],
      id_type: onboarding.id_type || IDTypes.passport,
    },
    mode: "all",
  });

  const doDrop = async (files: [File], category: string) => {
    const filename = await upload(
      `/kyb/document/${onboarding.id}`,
      files,
      category,
    );
    dispatch(addDocument({ filename, category }));
  };

  const { upload, error: uploadError, isLoading: isUploading } = useUploader();

  useKeyboardShortcut({
    key: "Enter",
    onKeyPressed: () => {
      step1Btn.current?.click();
      step3Btn.current?.click();
    },
  });

  const step1Btn = useRef<HTMLButtonElement>(null);

  const step3Btn = useRef<HTMLButtonElement>(null);

  const [mutate] = useUpdateOnboardingRegulatedMutation();

  const { getFiles } = useMergeFiles();

  const handleSend = async () => {
    const {
      corporate_shareholders,
      estimated_annual_revenue_range,
      estimated_monthly_volume_range,
      client_kyb_process,
      moves_client_funds,
      source_of_funds,
    } = methods.getValues();

    mutate({
      id: onboarding.id,
      company_name: onboarding.company_name.trim(),
      email: onboarding.email.trim(),
      company_category: onboarding.company_category.trim(),
      corporate_shareholders,
      files: getFiles(corporate_shareholders),
      estimated_monthly_volume_range: estimated_monthly_volume_range?.trim(),
      estimated_annual_revenue_range: estimated_annual_revenue_range?.trim(),
      moves_client_funds: moves_client_funds,
      client_kyb_process: client_kyb_process?.trim(),
      website_url: onboarding.website_url?.trim(),
      business_description: onboarding.business_description?.trim(),
      source_of_funds,
    });
  };

  useEffect(() => {
    if (uploadError) {
      enqueueSnackbar(t("Error uploading KYB"), errorSnack);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadError]);

  const idcompleted = () => {
    if (
      onboarding.id_type === IDTypes.passport &&
      onboarding?.document_id?.length
    ) {
      return true;
    }
    if (
      onboarding.id_type === IDTypes.INE &&
      onboarding?.document_ine_anverso?.length &&
      onboarding?.document_ine_reverso?.length
    ) {
      return true;
    }
    return false;
  };

  const steps = () => {
    if (step === 1) {
      return (
        <>
          <div className="flex gap-2 items-center">
            <span className="font-semibold text-3 self-center">
              {t("Legal Representative’s Power of Attorney")}
            </span>
          </div>

          <span className="text-3 text-[#737373]">
            {t("Add a simple copy as an image or PDF")}
          </span>
          <UploadOrShow
            multiUpload={false}
            isUploading={isUploading}
            category={"poder_representante_legal"}
            doDrop={doDrop}
            files={onboarding?.poder_representante_legal}
            label={
              onboarding?.poder_representante_legal?.length
                ? t("Other documents")
                : t("Legal Representative Power")
            }
          />
          <Button
            variant="contained"
            disabled={!onboarding?.poder_representante_legal?.length}
            onClick={() => dispatch(forwardStep())}
            ref={step1Btn}
          >
            {t("Continue")}
          </Button>
        </>
      );
    }

    if (step === 2) {
      return <RevenueForm />;
    }

    if (step === 3) {
      return (
        <>
          <ParticipantId />
          <Button
            variant="contained"
            disabled={!idcompleted()}
            onClick={() => dispatch(forwardStep())}
            ref={step3Btn}
          >
            {t("Continue")}
          </Button>
        </>
      );
    }

    if (step === 4) {
      return (
        <div>
          <span className="font-semibold text-3 self-center">
            {t("Contact information")}
          </span>

          <DynamicForm />
        </div>
      );
    }
  };

  return (
    <FormProvider {...methods}>
      {steps()}

      {step === 4 && (
        <Button
          variant="contained"
          disabled={
            !methods.formState.isValid ||
            isUploading ||
            !onboarding?.poder_representante_legal?.length ||
            !idcompleted()
          }
          onClick={handleSend}
        >
          {t("Send")}
        </Button>
      )}
    </FormProvider>
  );
};
